var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',[_c('h4',[_vm._v("Tiêu đề câu hỏi")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('CkContent',{attrs:{"validateName":"question","content":_vm.question.content},on:{"getData":function (value) { return _vm.question.content = value; }}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('h6',[_vm._v("Nhập đáp án")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"black"},on:{"click":_vm.addAnswer}},[_vm._v("Thêm đáp án ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1 text-center",attrs:{"headers":_vm.headers,"items":_vm.question.answers,"items-per-page":10},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}},{key:"item.check",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{model:{value:(item.check),callback:function ($$v) {_vm.$set(item, "check", $$v)},expression:"item.check"}})]}},{key:"item.content",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"dense":""},model:{value:(item.content),callback:function ($$v) {_vm.$set(item, "content", $$v)},expression:"item.content"}})]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"width":"500px"}},[_c('CkContent',{attrs:{"validateName":"question","content":item.description},on:{"getData":function (value) { return item.description = value; }}})],1)]}}])})],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-center justify-center"},[_c('div',{staticClass:"my-4"},[_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"blue-grey"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Hủy ")]),_c('v-btn',{attrs:{"color":"primary","type":"submit"},on:{"click":_vm.submitQuestion}},[_vm._v("Lưu ")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }